<template>
  <div>
    <BusinessPublicData :bid="currentBusiness.id" />
  </div>
</template>

<script>
import BusinessPublicData from '../../components/BusinessPublicData'

export default {
  components: {
    BusinessPublicData
  }
}
</script>
